class DocumentMapping {
    documentMapping = {
        "A_Life_Wealth_Venture_Brochure_1st_v2.pdf": 0,
        "A-Life_MediFlex_Brochure_Final_v2.pdf": 0,
        "A-Life_MediFlex-i_Brochure_04JUL24.pdf": 0,
        "A-Plus_Health_2_Brochure_Final_v2.pdf": 0,
        "ALPWT Slides_c010a8fe-6b7a-4b35-8895-d09a34f9b200_.pdf": 0,
        "InSights Training Slides Pilot Final_KL.pdf": 0,
        "Q3 2024 Unbeatable! Unstoppable! Unrivalled! Challenge 20240701 - Personal Sales - NEW.pdf": 0,
        "ClaimsProcedureforMCBilingual.pdf": 0,
        "ClinicalSurvey.pdf": 0,
        "FAQeng.pdf": 0,
        "Gold_Member_Guide_PTA001251_1121.pdf": 0,
        "PTA001231.0723_Website_User_Guide_for_Employee_(ENG).pdf": 0,
        "RoomBoardFees.pdf": 0,
        "Silver_Member_Guide_PTA001201_0821.pdf": 0,
        "20240101_0000021335_C02_M.pdf": 0,
        "21335-eWorksheet.pdf": 0,
        "A200350.pdf": 0,
        "A200418.pdf": 0,
        "A200420.pdf": 0,
        "A200469.pdf": 0,
        "A200471.pdf": 0,
        "A2005103.pdf": 0,
        "A200520.pdf": 0,
        "A200549.pdf": 0,
        "A200554.pdf": 0,
        "A200615.pdf": 0,
        "A200622.pdf": 0,
        "A200630.pdf": 0,
        "A200673.pdf": 0,
        "A200751.pdf": 0,
        "A200759.pdf": 0,
        "A200765.pdf": 0,
        "A200847.pdf": 0,
        "A200855.pdf": 0,
        "A200858.pdf": 0,
        "A200877.pdf": 0,
        "A200883.pdf": 0,
        "A200966.pdf": 0,
        "A200968.pdf": 0,
        "A200974.pdf": 0,
        "A200975.pdf": 0,
        "A201044.pdf": 0,
        "A201045.pdf": 0,
        "A201108.pdf": 0,
        "A201115.pdf": 0,
        "A201118.pdf": 0,
        "A201260.pdf": 0,
        "A210128.pdf": 0,
        "A210181.pdf": 0,
        "A210182.pdf": 0,
        "A210183.pdf": 0,
        "A210323.pdf": 0,
        "A210429.pdf": 0,
        "A210442.pdf": 0,
        "A210443.pdf": 0,
        "A210444.pdf": 0,
        "A210449.pdf": 0,
        "A210520.pdf": 0,
        "A210523.pdf": 0,
        "A210543.pdf": 0,
        "A210547.pdf": 0,
        "A210677.pdf": 0,
        "A210678.pdf": 0,
        "A210725.pdf": 0,
        "A210726.pdf": 0,
        "A210728.pdf": 0,
        "A210737.pdf": 0,
        "A210738.pdf": 0,
        "A210768.pdf": 0,
        "A210831.pdf": 0,
        "A210845.pdf": 0,
        "A210917.pdf": 0,
        "A210918.pdf": 0,
        "A210919.pdf": 0,
        "A210943.pdf": 0,
        "A210944.pdf": 0,
        "A211062.pdf": 0,
        "A211064.pdf": 0,
        "A211065.pdf": 0,
        "A211117.pdf": 0,
        "A211130.pdf": 0,
        "A211131.pdf": 0,
        "A211147.pdf": 0,
        "A211149.pdf": 0,
        "A211152.pdf": 0,
        "A211153.pdf": 0,
        "A211154.pdf": 0,
        "A211155.pdf": 0,
        "A211245.pdf": 0,
        "A211246.pdf": 0,
        "A211248.pdf": 0,
        "A211249.pdf": 0,
        "A211251.pdf": 0,
        "A211252 (Hong Kong).pdf": 0,
        "A211252 (Macau).pdf": 0,
        "A211253.pdf": 0,
        "A211254.pdf": 0,
        "A220138.pdf": 0,
        "A220144.pdf": 0,
        "A220158.pdf": 0,
        "A220159.pdf": 0,
        "A220162.pdf": 0,
        "A220188.pdf": 0,
        "A220194.pdf": 0,
        "A220196.pdf": 0,
        "A220229.pdf": 0,
        "A220275.pdf": 0,
        "A220366.pdf": 0,
        "A220373.pdf": 0,
        "A220377.pdf": 0,
        "A220381.pdf": 0,
        "A220386.pdf": 0,
        "A220387.pdf": 0,
        "A220388.pdf": 0,
        "A220447.pdf": 0,
        "A220538.pdf": 0,
        "A220542.pdf": 0,
        "A220547.pdf": 0,
        "A220550.pdf": 0,
        "A220551.pdf": 0,
        "A220555.pdf": 0,
        "A220560.pdf": 0,
        "A220620.pdf": 0,
        "A220634.pdf": 0,
        "A220655.pdf": 0,
        "A220656.pdf": 0,
        "A220687.pdf": 0,
        "A220742.pdf": 0,
        "A220764.pdf": 0,
        "A220780.pdf": 0,
        "A220781.pdf": 0,
        "A220857.pdf": 0,
        "A220944.pdf": 0,
        "A221072.pdf": 0,
        "A230328.pdf": 0,
        "A240705.pdf": 0,
        "A240712.pdf": 0,
        "A240782.pdf": 0,
        "15959-eWorksheet.pdf": 0,
        "20240101_0000015595_001_3045D.pdf": 0,
        "20240101_0000015595_B00_30220.pdf": 0,
        "20240101_0000015595_B00_30230.pdf": 0,
        "20240101_0000015595_C00_30210.pdf": 0,
        "20818_Staff_Medical_Beneift_Scheme_Handbook_2023to2026_PolyU_eng(20240402).pdf ": 0,
        "20818-eWorksheet.pdf ": 0,
        "20230701_0000020818_002_M.pdf": 0
    };
    constructor () {
        let docIdx = 0;
        for (const doc in this.documentMapping) {
            this.documentMapping[++docIdx] = doc;
        };
    }
    map(docIdx) {
        return this.documentMapping[docIdx];
    }
}

module.exports = DocumentMapping;