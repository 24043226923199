import { useContext, useCallback } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useParams } from 'react-router-dom';
import { useChatbot } from "#hooks/chatbot";
// Context
import { ChatHistoryContext } from '#contexts/chatHistory';

// Reducers
import { chatHistoryAction } from '#reducers/chatHistory';

// Adapters
import axios from '#adapters/axios';
import { toolCallType } from '#config/config';
import theme from '#config/theme';


const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST + "/api";

const toolCallMessageMap = {
  [toolCallType.GetCurrentAgentProfile]: {
    toolCallBegin: "Retrieving current agent profile",
    toolCallEnd: "Retrieved current agent profile"
  },
  [toolCallType.GetAvailableProducts]: {
    toolCallBegin: "Retrieving available products",
    toolCallEnd: "Retrieved available products"
  },
  [toolCallType.GetLeads]: {
    toolCallBegin: "Retrieving leader information",
    toolCallEnd: "Retrieved leader information"
  },
  [toolCallType.SearchFromInsuranceProductKnowledgeBase]: {
    toolCallBegin: "Retrieving insurance product knowledge base",
    toolCallEnd: "Retrieved insurance product knowledge base"
  }
};


const useChatHistory = () => {
  const { chatbot } = useChatbot();
  const { agentCode, company } = useParams();
  const { chatHistory, dispatchChatHistory } = useContext(ChatHistoryContext);


  const onChatModelStream = (data) => {
    const { chunk: {
      kwargs
    } } = data;
    const { content } = kwargs;
    if (content?.length)
      return dispatchChatHistory({
        type: chatHistoryAction.STREAMING,
        payload: {
          content
        }
      });
  };

  const onToolStart = (data) => {
    return dispatchChatHistory({
      type: chatHistoryAction.TOOL_CALL_BEGIN,
      payload: {
        content: toolCallMessageMap[data.name].toolCallBegin
      }
    });
  };

  const onToolEnd = (data) => {
    return dispatchChatHistory({
      type: chatHistoryAction.TOOL_CALL_END,
      payload: {
        content: toolCallMessageMap[data.name].toolCallEnd
      }
    });
  };

  const sendMessage = async (query, {
    model, collection
  }) => {
    const URL = `${ BACKEND_HOST }/nudge-engine/chat-stream`;
    dispatchChatHistory({
      type: chatHistoryAction.QUERY_START,
      payload: {
        query,
      }
    });
    return fetchEventSource(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        conversationId: chatbot.conversationId,
        agentCode,
        userMessage: query,
        // onlyMalaysiaProducts: chatbot.onlyMalaysiaProducts,
        onlyMalaysiaProducts: true,
        model : chatbot.model || model,
        collection: collection || chatbot.collection || theme[company].collection
      }),
      onclose: (event) => {
        dispatchChatHistory({
          type: chatHistoryAction.QUERY_END,
        });
      },
      onmessage: (stream) => {
        try {
          const data = JSON.parse(stream.data);
          const { type } = data;
          switch (type) {
            case 'on_chat_model_stream': {
              return onChatModelStream(data);
            }
            case 'on_chat_model_end': {
              const { output } = data;
              const { kwargs } = output;

              const isToolCall = kwargs.tool_calls.length > 0;
              if (isToolCall) break;

              const isStreaming = kwargs.content.length > 0;
              if (isStreaming) {
                return dispatchChatHistory({
                  type: chatHistoryAction.STREAMING_END,
                  payload: {
                    content: kwargs.content
                  }
                });
              }
              break;
            }
            case 'on_tool_start': {
              return onToolStart(data);
            }
            case 'on_tool_end': {
              return onToolEnd(data);
            }
            default:
              break;
          }
        } catch (error) {
          console.error("Error in onmessage:", error);
        }
      },
      onerror: (error) => {
        console.error("Error in fetchEventSource:", error);
        dispatchChatHistory({
          type: chatHistoryAction.QUERY_ERROR,
        });
      },
      openWhenHidden: true,
    });
  };

  const fetchChatHistory = useCallback(async (conversationId) => {
    const URL = `${ BACKEND_HOST }/nudge-engine/state/${ conversationId }`;
    try {
      const response = await axios.get(URL);
      const { data } = response;
      const messages = data.values.messages.reduce((accMessages, message) => {
        try {
          const { id, kwargs } = message;
          if (id.includes('HumanMessage')) {
            accMessages.push({
              role: 'user',
              status: 'done',
              content: {
                text: kwargs.content,
                type: 'message',
              }
            });
          } else if (id.includes('ToolMessage')) {
            const { name } = kwargs;
            accMessages = accMessages.concat([
              {
                role: 'assistant',
                status: 'done',
                content: {
                  text: toolCallMessageMap[name].toolCallBegin,
                  type: 'tool_call_start'
                }
              },
              {
                role: 'assistant',
                status: 'done',
                content: {
                  text: toolCallMessageMap[name].toolCallEnd,
                  type: 'tool_call_end'
                }
              }
            ]);
          } else if (id.includes('AIMessageChunk')) {
            if (kwargs.content.length)
              accMessages.push({
                role: 'assistant',
                status: 'done',
                content: {
                  text: kwargs.content,
                  type: 'message',
                }
              });
          }
          return accMessages;
        } catch (error) {
          return accMessages;
        }


      }, []);
      dispatchChatHistory({
        type: chatHistoryAction.FETCH_HISTORY,
        payload: {
          messages
        }
      });
    } catch (error) {
      console.log("Error in fetchChatHistory: ", error);
    }

  }, [dispatchChatHistory]);
  return {
    chatHistory,
    dispatchChatHistory,
    sendMessage,
    fetchChatHistory
  };
};

export default useChatHistory;