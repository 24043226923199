const chatbotAction = {
  TOGGLE_CHATBOT: "TOGGLE_CHATBOT",
  INITIALIZE_CHATBOT: "INITIALIZE_CHATBOT",
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
  TOGGLE_ONLY_MALAYSIA_PRODUCTS: "TOGGLE_ONLY_MALAYSIA_PRODUCTS",
  SET_CHATBOT_COLLECTION: "SET_CHATBOT_COLLECTION",
};

const chatbotInitialState = {
  isLoaded: false,
  isOpen: false,
  conversationId: null,
  messages: [],
  notificationMessage: null,
  onlyMalaysiaProducts: false,
  collection: null
};

const chatbotReducer = (state, action) => {
  switch (action.type) {
    case chatbotAction.TOGGLE_CHATBOT: {
      const { isOpen } = action.payload;
      return {
        ...state,
        isOpen
      };
    }
    case chatbotAction.INITIALIZE_CHATBOT: {
      const { conversationId } = action.payload;
      return {
        ...state,
        isLoaded: true,
        conversationId
      };
    }
    case chatbotAction.PUSH_NOTIFICATION: {
      const { notificationMessage, conversationId } = action.payload;
      return {
        ...state,
        conversationId,
        notificationMessage
      };
    }
    case chatbotAction.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notificationMessage: null
      };
    }
    case chatbotAction.TOGGLE_ONLY_MALAYSIA_PRODUCTS: {
      return {
        ...state,
        onlyMalaysiaProducts: !state.onlyMalaysiaProducts
      };
    }

    case chatbotAction.SET_CHATBOT_COLLECTION: {
      const { collection } = action.payload;
      return {
        ...state,
        collection: collection
      };
    }
    default:
      return state;
  }
};

export {
  chatbotAction,
  chatbotInitialState,
  chatbotReducer
};
