import { useLayoutEffect, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '#components/portal/portal.scss';
import aimLogo from '../assets/aim-full-logo.png'
import hpeLogo from '../assets/hpe_logo.png'

// Components
import { PortalBanner, PortalBody, PortalFooter } from '#components/portal';
import { FloatingChatbot } from '#components/floatingChatbot';
// Hooks
import { useTheme, useChatbot } from '#hooks';

const backendHost = process.env.REACT_APP_LLM_SERVER_URL;

const Portal = () => {
  useTheme();
  const { agentCode, company } = useParams();
  const { chatbot, subscribeNotification, initializeChatbot } = useChatbot();
  const { isLoaded } = chatbot;
  const [authenticated, setAuthenticated] = useState(false);

  useLayoutEffect(() => {
    if (chatbot.isLoaded) return;
    initializeChatbot();
  }, [initializeChatbot, chatbot.isLoaded]);

  useLayoutEffect(() => {
    const abortController = new AbortController();
    if (agentCode)
      subscribeNotification({ agentCode, signal: abortController.signal });

    return () => {
      abortController.abort();
    };
  }, [subscribeNotification, agentCode]);

  useEffect(() => {
    const aimContainer = document.getElementById('aim-container');
    if (!aimContainer) {
      return;
    }
    if (company.indexOf('sdk-demo') === -1) {
      aimContainer.style.display = 'none';
    }
    if (!authenticated && company.indexOf('smartone') >= 0) {
      aimContainer.style.display = 'none';
    } else {
      aimContainer.style.display = 'block';
    }
  }, [company, authenticated]);



  useEffect(() => {
    const generatingToken = async (e) => {
      const response = await fetch(`${ backendHost }/api/tokens/generate`, {
        method: 'POST',
        body: JSON.stringify({
          user_id: agentCode,
          channel: company,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      window.setReceiveToken(data.data.access_token, data.data.refresh_token);
    };
    window.addEventListener('initiate_chatbot', generatingToken);

    return () => {
      window.removeEventListener('initiate_chatbot', generatingToken);
    };
  }, [agentCode, company]);
  
  if (!authenticated & company.indexOf('smartone') >= 0) {
    return (
      <div className="login">
        <div className="loginContainer">
          <div className="loginLogo">
            <img src={ hpeLogo } alt="hpe-logo" />
            <span>x</span>
            <img src={aimLogo} alt="aim-logo" />
          </div>
          <div className="loginForm">
            <span className="loginPinLabel">Access Code: </span>
            <input
              className="loginPinInput"
              type="password"
              name="pin"
            />
            <button
              className="loginButton"
              type="submit"
              onClick={(value) => {
                const pin = document.querySelector('.loginPinInput').value;
                if (pin === '0315') {
                  setAuthenticated(true);
                } else {
                  alert('Invalid PIN');
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
  if (company.indexOf('sdk-demo') >= 0) {
    return (
      <div className="portal">
        <PortalBody />
      </div>
    );
  }

  return (
    <div className="portal">
      { isLoaded && <FloatingChatbot /> }
      <PortalBody />
      {/* <PortalBanner />
      <PortalFooter /> */}
    </div>
  );
};

export default Portal;
