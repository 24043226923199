import React, { useEffect } from 'react';
import Select from 'react-select';
import userLogo from '../../assets/user-solid.svg';
import './TitleBar.scss';
import newChatLogo from '../../assets/new-chat.svg';
import { chatbotMode } from '#config/config';

// Hooks
import { useChatbot } from '#hooks/chatbot';
import { useChatHistory } from '#hooks/chatHistory';
import { useParams } from 'react-router-dom';

const TitleBar = ({ name, role, dialog, logout, mode, handleSetMode }) => {
  const { company = 'aim' } = useParams();
  const { chatbot, toggleOnlyMalayProducts, initializeChatbot, setChatbotCollection } = useChatbot();
  const { chatHistory } = useChatHistory();
  const isDisabled = chatHistory.status === chatbotMode.Streaming;
  const options = [
    { value: 'aia_eb_v1_600_200', label: 'AIA Member' },
    { value: 'aia_polyu_v1_600_200', label: 'PolyU Member' },
    { value: 'aia_citibank_v1_600_200', label: 'Citibank Member' }
  ];
  const onClickReset = () => {
    if (isDisabled) return;
    initializeChatbot(true);
  };

  const onCollectionChange = (e) => {
    setChatbotCollection(e.value);
    initializeChatbot(true);
  };

  return (
    <div className="title-bar">
      <div className="title-options-bar">
        <div className="title-left-group">
          { name ? (
            <div>
              <img className="user-logo" alt="user" src={ userLogo }></img>
              { name }
            </div>
          ) : (
            <div>Welcome back!</div>
          ) }
          { company === 'aim' && (
            <Select
              defaultValue={ options[0] }
              options={ options }
              onChange={ onCollectionChange }
            ></Select>
          )
            // <input
            //   type="checkbox"
            //   label="Malaysia Products Only"
            //   checked={chatbot.onlyMalaysiaProducts}
            //   onChange={() => toggleOnlyMalayProducts()}
            // ></input>
            // <span>Malaysia Products Only</span>
          }
        </div>
        <div className="title-right-group">
          <button>
            <img
              className={ `user-logo ${ isDisabled && 'user-logo--disabled' }` }
              alt="user"
              src={ newChatLogo }
              onClick={ onClickReset }
            ></img>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
